const CVVHelp = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_4259_37352)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2Z"
        fill="white"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2512 10.586V11.5751H9.53689V10.1465C10.0741 10.0183 10.4862 9.81681 10.7732 9.54208C11.0601 9.26736 11.2036 8.94074 11.2036 8.56223C11.2036 8.33024 11.1501 8.12114 11.0433 7.93494C10.9365 7.74874 10.7884 7.60374 10.5992 7.49996C10.4099 7.39617 10.1932 7.34428 9.94898 7.34428C9.73531 7.34428 9.53689 7.38549 9.35374 7.46791C9.17059 7.55033 9.01339 7.6709 8.88213 7.82963C8.75088 7.98836 8.59367 8.38824 8.59367 8.38824L7.91602 8.19593C7.99538 7.89068 8.13122 7.62206 8.32352 7.39007C8.51583 7.15808 8.7524 6.97951 9.03323 6.85435C9.31406 6.7292 9.62236 6.66663 9.95814 6.66663C10.2512 6.66663 10.5213 6.71394 10.7686 6.80857C11.0158 6.90319 11.231 7.03445 11.4142 7.20234C11.5973 7.37023 11.7393 7.56711 11.84 7.793C11.9407 8.01888 11.9911 8.26309 11.9911 8.5256C11.9911 8.84306 11.9209 9.13763 11.7805 9.4093C11.6401 9.68097 11.4386 9.91754 11.1761 10.119C10.9136 10.3205 10.6053 10.4761 10.2512 10.586ZM10.1596 13.26C10.0802 13.3089 9.99172 13.3333 9.89404 13.3333C9.79636 13.3333 9.70783 13.3089 9.62847 13.26C9.5491 13.2112 9.485 13.1456 9.43616 13.0631C9.38732 12.9807 9.3629 12.8937 9.3629 12.8022C9.3629 12.7106 9.38732 12.6236 9.43616 12.5412C9.485 12.4588 9.5491 12.3931 9.62847 12.3443C9.70783 12.2954 9.79636 12.271 9.89404 12.271C9.99172 12.271 10.0802 12.2954 10.1596 12.3443C10.239 12.3931 10.3031 12.4588 10.3519 12.5412C10.4008 12.6236 10.4252 12.7106 10.4252 12.8022C10.4252 12.8937 10.4008 12.9807 10.3519 13.0631C10.3031 13.1456 10.239 13.2112 10.1596 13.26Z"
      fill="#1A1C1F"
    />
    <defs>
      <filter
        id="filter0_d_4259_37352"
        x="0"
        y="0"
        width="20"
        height="20"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4259_37352"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4259_37352"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default CVVHelp;
