import clsxm from '@/utils/clsxm';

const Secured = ({ className, fill = '#096BFF' }: SVGJSX) => (
  <svg
    width="18"
    height="22"
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsxm('text-primary', className)}
  >
    <path
      d="M15.5408 3.12009L10.0408 1.06009C9.47078 0.850088 8.54078 0.850088 7.97078 1.06009L2.47078 3.12009C1.41078 3.52009 0.550781 4.76009 0.550781 5.89009V13.9901C0.550781 14.8001 1.08078 15.8701 1.73078 16.3501L7.23078 20.4601C8.20078 21.1901 9.79078 21.1901 10.7608 20.4601L16.2608 16.3501C16.9108 15.8601 17.4408 14.8001 17.4408 13.9901V5.89009C17.4508 4.76009 16.5908 3.52009 15.5408 3.12009ZM12.4808 8.72009L8.18078 13.0201C8.03078 13.1701 7.84078 13.2401 7.65078 13.2401C7.46078 13.2401 7.27078 13.1701 7.12078 13.0201L5.52078 11.4001C5.23078 11.1101 5.23078 10.6301 5.52078 10.3401C5.81078 10.0501 6.29078 10.0501 6.58078 10.3401L7.66078 11.4201L11.4308 7.65009C11.7208 7.36009 12.2008 7.36009 12.4908 7.65009C12.7808 7.94009 12.7808 8.43009 12.4808 8.72009Z"
      fill={fill}
    />
  </svg>
);

export default Secured;
