import { useState } from 'react';
import CreditCards from './components/CreditCards';
import TokenizedPin from './components/TokenizedPin';

interface IPayWithTokenizedCardProps {
  phone: string;
  setStep: (e: number) => void;
  config: ITAPPaymentProps;
}

const PayWithTokenizedCard = ({
  phone,
  config,
  setStep
}: IPayWithTokenizedCardProps) => {
  const [tokenizedStep, setTokenizedStep] = useState(0);
  const [selectedCard, setSelectedCard] = useState<ICreditCardProps | null>(
    null
  );

  const renderStep = () => {
    switch (tokenizedStep) {
      case 0:
        return (
          <CreditCards
            setTokenizedStep={setTokenizedStep}
            setSelected={setSelectedCard}
          />
        );
      case 1:
        return <TokenizedPin config={config} phone={phone} setStep={setStep} />;
    }
  };

  return <div className="pay-with-tokenized-card">{renderStep()}</div>;
};

export default PayWithTokenizedCard;
