import React from 'react';
import OTP from './components/OTP';

interface IPayWithWalletProps {
  phone: string;
  setStep: (e: number) => void;
  config: ITAPPaymentProps;
}

const PayWithWallet = ({ phone, config, setStep }: IPayWithWalletProps) => {
  return (
    <div className="pay-with-wallet">
      <OTP config={config} phone={phone} setStep={setStep} />
    </div>
  );
};

export default PayWithWallet;
