import { useState } from 'react';
import { Tab } from '@headlessui/react';
import { Icon } from '@iconify/react';
import * as SVG from '@/assets/svgs';
import { H3, Paragraph } from '@/components/ui/Typography';
import { Button } from './components/ui/Buttons';
import clsxm from '@/utils/clsxm';
import PayWithCowry from './components/PayWithCowry';
import PayWithTransfer from './components/PayWithTransfer';
import PayWithCard from './components/PayWithCard.tsx';

const App = (config: ITAPPaymentProps) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [step, setStep] = useState(0);
  const [selectedOption, setSelectedOption] =
    useState<IPayWithCowryOptions | null>({
      icon: null,
      label: ''
    });

  const TABS: TabsProps[] = [
    {
      tabIndex: 'tab1',
      icon: SVG.PayWithCowry,
      triggerMobile: 'Pay with Cowry',
      triggerDesktop: 'Cowry',
      content: <PayWithCowry config={config!} step={step} setStep={setStep} />
    },
    {
      tabIndex: 'tab2',
      icon: SVG.PayWithTransfer,
      triggerMobile: 'Pay with Transfer',
      triggerDesktop: 'Transfer',
      content: (
        <PayWithTransfer step={step} setStep={setStep} config={config!} />
      )
    },
    {
      tabIndex: 'tab3',
      icon: SVG.PayWithCard,
      triggerMobile: 'Pay with Card',
      triggerDesktop: 'Card',
      content: <PayWithCard config={config!} />
    }
  ];

  return (
    <div className="flex h-full w-full flex-col items-center justify-center overflow-y-auto pb-14 sm:mx-auto sm:max-h-screen  sm:min-h-screen">
      {/* header */}
      {!isPanelOpen && (
        <header className="block sm:hidden">
          <H3 className="mobile-only mb-8 pt-5 text-sm uppercase text-success-darken-20 sm:hidden">
            TouchandPay Checkout
          </H3>
        </header>
      )}
      {/* main */}
      <Tab.Group
        as="div"
        className="tabs w-full bg-white sm:flex sm:max-w-lg sm:flex-row sm:shadow-sm"
        defaultValue={TABS[0].tabIndex}
      >
        <Tab.List
          aria-label="tabs example"
          className={clsxm(
            'tabs-list flex flex-col shadow-tab sm:bg-widget-background sm:py-6',
            { 'hidden sm:flex': isPanelOpen }
          )}
        >
          <header className="px-5 sm:px-0">
            <div className="mobile-only my-8 block rounded-md border border-solid border-widget-border bg-widget-background p-4 sm:hidden">
              <Paragraph className="mb-1">You’re paying</Paragraph>
              <Paragraph className="mb-1 font-bold text-primary">
                {config.currency} {config!?.amount.toFixed(2)}
              </Paragraph>
              <Paragraph className="">
                Use one of the payment methods below to pay to{' '}
                {config?.recipient}
              </Paragraph>
            </div>
            <div className="desktop-only hidden px-5 py-3 sm:block">
              <H3 className="text-sm uppercase text-primary ">Pay With</H3>
            </div>
          </header>
          {TABS.map((tab) => (
            <Tab
              as={Button}
              flat
              key={tab.tabIndex}
              onClick={() => {
                setIsPanelOpen(true);
                setStep(0);
              }}
              className={({ selected }) =>
                clsxm(
                  'tabs-trigger relative flex items-center justify-between border-b border-tab-border bg-white px-5 py-5 font-semibold text-tab-text outline-0 last:border-b-0 hover:bg-primary-5',
                  {
                    'sm:after:content[" "] sm:text-primary sm:after:absolute sm:after:-right-1 sm:after:z-20 sm:after:h-2 sm:after:w-2 sm:after:rounded-full sm:after:bg-primary':
                      selected
                  }
                )
              }
            >
              {({ selected }) => (
                <>
                  <div className="mobile-only flex items-center gap-x-5 sm:hidden">
                    <tab.icon
                      stroke={selected && isPanelOpen ? '#0E60E2' : '#6C7884'}
                    />
                    <span className="">{tab.triggerMobile}</span>
                  </div>
                  <div className="mobile-only inline-block sm:hidden">
                    <SVG.ChevronRight />
                  </div>

                  <div className="desktop-only hidden items-center gap-x-5 sm:flex">
                    <tab.icon
                      stroke={selected ? '#0E60E2' : '#6C7884'}
                      className="tabs-icon"
                    />
                    <span className="">{tab.triggerDesktop}</span>
                  </div>
                </>
              )}
            </Tab>
          ))}
        </Tab.List>

        <Tab.Panels
          className={clsxm('z-50 w-full bg-white', {
            'hidden w-full sm:flex': !isPanelOpen
          })}
        >
          {TABS.map((tab) => (
            <Tab.Panel className="tabs-panel h-full w-full" key={tab.tabIndex}>
              <header className="border-widdget-border flex items-center gap-x-5 border-b bg-widget-background p-6 font-semibold text-tab-text sm:hidden">
                <tab.icon stroke={'#0E60E2'} />
                <span className="">{tab.triggerMobile}</span>
              </header>

              <div className="tab-content h-full p-6">{tab.content}</div>
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>

      <footer className="mt-10 px-5">
        <div className="mx-auto flex flex-wrap items-center justify-center gap-4 sm:hidden">
          <Button
            className={clsxm(
              'mobile-only gap-x-2 border border-widget-border bg-widget-background text-tab-text sm:hidden',
              { hidden: !isPanelOpen }
            )}
            onClick={() => {
              setIsPanelOpen(false);
              setStep(0);
              setSelectedOption(null);
            }}
            flat
          >
            <Icon
              icon="gg:arrows-exchange-alt"
              className="-rotate-45 transform text-xl"
            />
            Change Payment Method
          </Button>
          <Button
            className="gap-x-2 border border-widget-border bg-widget-background text-tab-text"
            flat
          >
            <Icon icon="material-symbols:close-rounded" />
            Cancel Payment
          </Button>
        </div>

        <div className="mt-10 flex items-center justify-center gap-x-3">
          <div>
            <SVG.Secured />
          </div>
          <Paragraph className="font-bold text-primary">
            <span className="font-medium text-black">Secured by </span> Cowry
          </Paragraph>
        </div>
      </footer>
    </div>
  );
};

export default App;
