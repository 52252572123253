import clsxm from '@/utils/clsxm';

const LinearProgress = ({ className, fill = '#0E60E2' }: SVGJSX) => (
  <svg
    width="252"
    height="8"
    viewBox="0 0 252 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsxm('text-primary', className)}
  >
    <rect width="252" height="8" rx="4" fill="#F3F5F7" />
    <path
      className="animate-[scroll_1s_linear_infinite]"
      d="M1 4C1 2.34315 2.34315 1 4 1H152V7H4C2.34314 7 1 5.65685 1 4Z"
      fill={fill}
    />
  </svg>
);

export default LinearProgress;
