import React from 'react';
import './styles/index.scss';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

export const config: ITAPPaymentProps = {
  fullname: 'Chidike Walter',
  email: 'Chidikewalter@gmail.com',
  amount: 7900,
  currency: 'NGN',
  recipient: 'Chowdeck'
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App {...config} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
