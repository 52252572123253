import clsxm from '@/utils/clsxm';

const ClipBoard = ({ className, fill = '#333D47' }: SVGJSX) => (
  <svg
    width="9"
    height="11"
    viewBox="0 0 9 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsxm('text-primary', className)}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.67361 3.03201C1.34761 3.03201 1.08333 3.29509 1.08333 3.61962V8.79056C1.08333 9.11508 1.34761 9.37816 1.67361 9.37816H5.45139C5.77739 9.37816 6.04167 9.11508 6.04167 8.79056V5.18426C6.04167 5.02842 5.97948 4.87896 5.86878 4.76876L4.29702 3.20412C4.18632 3.09392 4.03618 3.03201 3.87963 3.03201H1.67361ZM0.375 3.61962C0.375 2.90566 0.956408 2.32688 1.67361 2.32688H3.87963C4.22404 2.32688 4.55435 2.46308 4.79789 2.70552L6.36965 4.27016C6.61318 4.5126 6.75 4.84141 6.75 5.18426V8.79056C6.75 9.50452 6.16859 10.0833 5.45139 10.0833H1.67361C0.956408 10.0833 0.375 9.50451 0.375 8.79056V3.61962Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.26389 1.26919C2.26389 1.07447 2.42245 0.916626 2.61805 0.916626H4.58796C4.93238 0.916626 5.26268 1.05282 5.50622 1.29526L7.78631 3.56503C8.02985 3.80747 8.16667 4.13628 8.16667 4.47913V7.85039C8.16667 8.0451 8.0081 8.20295 7.8125 8.20295C7.6169 8.20295 7.45833 8.0451 7.45833 7.85039V4.47913C7.45833 4.32329 7.39614 4.17383 7.28545 4.06363L5.00535 1.79386C4.89466 1.68366 4.74452 1.62175 4.58796 1.62175H2.61805C2.42245 1.62175 2.26389 1.46391 2.26389 1.26919Z"
      fill={fill}
    />
  </svg>
);

export default ClipBoard;
