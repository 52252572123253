import * as SVG from '@/assets/svgs';
import { Button } from './ui/Buttons';
import Image from './ui/Image';
import { H5, Paragraph } from './ui/Typography';

interface IPaymentStatusProps {
  config: ITAPPaymentProps;
  status: string;
  onClick: () => void;
}

const PaymentStatus = ({ config, status, onClick }: IPaymentStatusProps) => {
  const { amount, currency, recipient } = config;

  return (
    <div className="m-auto flex max-h-full  min-h-full w-full flex-col justify-center py-10 text-center sm:py-0">
      <div className="mb-10 flex justify-center">
        <Image
          className="h-20 w-20"
          src={status === 'success' ? SVG.SuccessEmoji : SVG.FailEmoji}
        />
      </div>
      <div className="text-center">
        <H5 className="mb-2 text-2xl font-bold text-black">
          {status === 'success' ? 'Payment successful' : 'Payment failed'}
        </H5>
        <Paragraph>
          {status === 'success' ? (
            <span>
              Your payment of{' '}
              <span className="font-bold text-primary">
                {currency}
                {amount}
              </span>{' '}
              to {recipient} has been made successfully.
            </span>
          ) : (
            <span>
              We couldn’t process your payment of{' '}
              <span className="font-bold text-primary">
                {currency}
                {amount}
              </span>{' '}
              to {recipient}, due to insufficient balance. Fund your account and
              try again.
            </span>
          )}
        </Paragraph>
      </div>

      <Button onClick={onClick} className="mt-10 text-sm">
        {status === 'success' ? 'Done' : 'Try Again'}
      </Button>

      {status === 'success' && (
        <Button
          flat
          className="mt-4 text-sm hover:bg-transparent hover:underline"
        >
          Check transaction status
        </Button>
      )}
    </div>
  );
};

export default PaymentStatus;
