import { useEffect, useState } from 'react';
import * as SVG from '@/assets/svgs';
import { H5, Paragraph } from '@/components/ui/Typography';
import TabContentHeader from '@/components/TabContentHeader';
import { Button } from '@/components/ui/Buttons';

interface IAccountDetailsProps {
  config: ITAPPaymentProps;
  setStep: (e: number) => void;
  setStatus: (e: keyof typeof PaymentStatus) => void;
}

const AccountDetails = ({
  config,
  setStep,
  setStatus
}: IAccountDetailsProps) => {
  const { recipient, amount, currency } = config;
  const [isChecking, setIsChecking] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          setIsExpired(true);
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
          setIsExpired(false);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const handleCheckTransStatus = () => {
    setIsChecking(true);
    setTimeout(() => {
      setIsChecking(false);
      setStep(1);
      setTimeout(() => {
        setStatus('success');
      }, 3000);
    }, 3000);
  };

  if (isChecking) {
    return (
      <div className="m-auto flex max-h-full  min-h-full w-full flex-col justify-center gap-8 py-10 text-center sm:py-0">
        <div>
          <SVG.CircularProgress className="inline-block animate-spin" />
        </div>
        <Paragraph>Checking transaction status</Paragraph>
      </div>
    );
  }

  if (isExpired) {
    return (
      <div className="m-auto flex max-h-full  min-h-full w-full flex-col justify-center gap-8 py-10 text-center sm:py-0">
        <div>
          <SVG.Error className="inline-block h-10 w-10" />
        </div>
        <Paragraph className="fonnt-semibold text-base">
          Account Expired
        </Paragraph>
        <Button
          flat
          className="border border-widget-border bg-widget-background text-tab-text hover:bg-transparent"
          onClick={() => {
            setMinutes(1);
            setSeconds(0);
            setIsExpired(false);
          }}
        >
          Retry
        </Button>
      </div>
    );
  }

  return (
    <div className="account-details">
      <TabContentHeader config={config} />
      <div className="flex flex-col justify-center py-5 text-center">
        <H5 className="mb-2 text-base text-tab-text">
          Transfer {currency} {amount}
        </H5>
        <Paragraph className="mx-auto text-xs leading-6 sm:max-w-xs">
          Please proceed to your mobile banking/internet banking app to complete
          your bank transfer payment to {recipient}
        </Paragraph>
        <div className="my-10 block rounded-md border border-solid border-widget-border bg-widget-background p-5">
          <Paragraph className="mb-2 text-[15px] text-tab-text">
            Wema Bank
          </Paragraph>
          <Paragraph className="mb-2 text-xl font-bold text-tab-text">
            <span>2180110371</span>
            <span>
              <SVG.ClipBoard className="relative bottom-1 left-1 inline-flex" />
            </span>
          </Paragraph>
          <Paragraph className="mx-auto max-w-[16rem] text-xs">
            {!isExpired ? (
              <>
                This account expires after in{' '}
                <span className="font-semibold text-primary">
                  {minutes < 10 ? `0${minutes}` : minutes} mins{' '}
                  {seconds < 10 ? `0${seconds}` : seconds} secs
                </span>{' '}
                and valid for this transaction only
              </>
            ) : (
              'Acount has expired'
            )}
          </Paragraph>
        </div>
        <Button onClick={handleCheckTransStatus}>
          Check transaction status
        </Button>
      </div>
    </div>
  );
};

export default AccountDetails;
