import clsxm from '@/utils/clsxm';

const Visa = ({ className }: SVGJSX) => (
  <svg
    width="62"
    height="19"
    viewBox="0 0 62 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsxm('text-primary', className)}
  >
    <path
      d="M31.9029 5.36811C31.8677 8.14305 34.3759 9.69149 36.2653 10.6122C38.2065 11.5568 38.8586 12.1626 38.8508 13.0074C38.8364 14.3002 37.3024 14.8708 35.867 14.8929C33.3627 14.9317 31.9065 14.2168 30.7489 13.6761L29.8467 17.8975C31.0082 18.4327 33.1588 18.8994 35.3889 18.9199C40.6239 18.9199 44.0487 16.3358 44.0672 12.3293C44.0877 7.2445 37.034 6.96305 37.0822 4.69028C37.0988 4.00113 37.7564 3.26571 39.1974 3.07872C39.9106 2.98426 41.8795 2.91197 44.1118 3.93992L44.988 -0.144415C43.7875 -0.581524 42.2446 -1.00008 40.3236 -1.00008C35.3964 -1.00008 31.9308 1.6192 31.9029 5.36811ZM53.4067 -0.648272C52.4508 -0.648272 51.6453 -0.0906811 51.2858 0.76498L43.808 18.6199H49.039L50.08 15.7431H56.4723L57.0761 18.6199H61.6867L57.6634 -0.648272H53.4067ZM54.1386 4.55679L55.6482 11.7922H51.5137L54.1386 4.55679ZM25.5605 -0.648031L21.4371 18.6197H26.4219L30.5434 -0.648512H25.5605M18.1863 -0.648512L12.9978 12.4664L10.899 1.31534C10.6528 0.0705242 9.68024 -0.648272 8.60024 -0.648272H0.118795L0 -0.0887527C1.7412 0.289078 3.71952 0.898476 4.91807 1.55052C5.65157 1.94884 5.86072 2.29703 6.10169 3.24354L10.0769 18.6199H15.3446L23.4207 -0.648272L18.1863 -0.648512Z"
      fill="url(#paint0_linear_4242_37855)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4242_37855"
        x1="28.3599"
        y1="19.3195"
        x2="28.9365"
        y2="-1.13861"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#222357" />
        <stop offset="1" stopColor="#254AA5" />
      </linearGradient>
    </defs>
  </svg>
);

export default Visa;
