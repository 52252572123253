import { useState } from 'react';
import OtpInput from 'react18-input-otp';
import TabContentHeader from '@/components/TabContentHeader';
import { Button } from '@/components/ui/Buttons';
import { Paragraph } from '@/components/ui/Typography';
import PaymentStatus from '@/components/PaymentStatus';

interface ITokenizedPinProps {
  config: ITAPPaymentProps;
  phone: string;
  setStep: (e: number) => void;
}

const TokenizedPin = ({ config, phone, setStep }: ITokenizedPinProps) => {
  const [tokenizedPin, setTokenizedPin] = useState('');
  const [status, setStatus] = useState('');

  const onProceed = () => {
    console.log({ phone, tokenizedPin });
    setStatus('success');
  };

  if (status?.length > 0) {
    return (
      <PaymentStatus
        config={config}
        status={status}
        onClick={() => {
          setStep(0);
          setStatus('');
        }}
      />
    );
  }

  return (
    <div className="">
      <TabContentHeader config={config} />
      <div className="m-auto flex max-h-full  min-h-full w-full flex-col justify-center py-10 text-center sm:py-0">
        <Paragraph className="mx-auto max-w-[16rem] text-center">
          Generate a six digit on your Cowry App and enter Tokenized Pin below.
        </Paragraph>

        <form action="">
          <div className="pin my-10 flex w-full justify-center">
            <OtpInput
              value={tokenizedPin}
              onChange={(pin: any) => setTokenizedPin(pin)}
              numInputs={6}
              isInputNum
              shouldAutoFocus
              inputStyle="pin-input min-w-[3rem] w-12 max-[1030px]:w-[35px] max-[770px]:w-[42px] max-[541px]:w-[58.5px] max-[418px]:w-[46.5px] max-[380px]:w-[41px] max-[361px]:w-[39.5px] min-h-[3rem] bg-white border border-solid border-widget-border dark:bg-card-dark rounded-lg text-primary font-semibold focus:border-primary focus:outline-none bg-widget-background"
              containerStyle="gap-x-3"
            />
          </div>
        </form>

        <Button
          onClick={onProceed}
          className="mt-10 text-sm disabled:bg-btn-disabled hover:disabled:bg-btn-disabled"
          disabled={tokenizedPin.length !== 6}
        >
          Proceed
        </Button>
      </div>
    </div>
  );
};

export default TokenizedPin;
