import { useState } from 'react';
import PhoneNumber from './components/PhoneNumber';
import CowryPin from './components/CowryPin';
import PayWithCowryOptions from './components/PayWithCowryOptions';

interface IPayWithCowryProps {
  step: number;
  setStep: (e: number) => void;
  config: ITAPPaymentProps;
}

const PayWithCowry = ({ step, setStep, config }: IPayWithCowryProps) => {
  const [phone, setPhone] = useState('');

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <PhoneNumber phone={phone} setPhone={setPhone} setStep={setStep} />
        );
      case 1:
        return <CowryPin config={config} phone={phone} setStep={setStep} />;

      case 2:
        return (
          <PayWithCowryOptions
            config={config}
            phone={phone}
            setStep={setStep}
          />
        );
    }
  };

  return <div className="pay-with-cowry">{renderStep()}</div>;
};

export default PayWithCowry;
