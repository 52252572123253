import * as SVG from '@/assets/svgs';
import { Button } from '@/components/ui/Buttons';
import { Paragraph } from '@/components/ui/Typography';
import TabContentHeader from '@/components/TabContentHeader';
import PaymentStatus from '@/components/PaymentStatus';

interface IWaitingProps {
  config: ITAPPaymentProps;
  setStep: (e: number) => void;
  status: keyof typeof PaymentStatus | string;
  setStatus: (e: keyof typeof PaymentStatus | string) => void;
}

const Waiting = ({ config, setStep, status, setStatus }: IWaitingProps) => {
  if (status?.length > 0) {
    return (
      <PaymentStatus
        config={config}
        status={status}
        onClick={() => {
          setStep(0);
          setStatus('');
        }}
      />
    );
  }

  return (
    <div className="account-details">
      <TabContentHeader config={config} />
      <div className="flex w-full flex-col justify-center gap-8 py-5 text-center">
        <Paragraph>
          We’re waiting to receive your transfer. Please hold on as this may
          take up to a minute.
        </Paragraph>
        <div className="mx-auto flex w-full  items-start gap-x-2">
          <div className="flex flex-col items-center gap-y-1 text-[10px]">
            <SVG.SuccessCheck className="inline-block" />
            Sent
          </div>
          <div className="w-full rounded-full">
            <SVG.LinearProgress className="inline-block" />
          </div>
          <div className="flex flex-col items-center gap-y-1 text-[10px]">
            <div className="h-7 w-7 rounded-full bg-[#F3F5F7]" />
            Received
          </div>
        </div>
        <Button
          onClick={() => setStep(0)}
          flat
          variant="primary"
          className="hover:bg-transparent"
        >
          Show bank account
        </Button>
      </div>
    </div>
  );
};

export default Waiting;
