import { useEffect, useReducer, useRef, useState } from 'react';
import MaskedInput from 'react-text-mask';
import * as SVG from '@/assets/svgs';
import TabContentHeader from '@/components/TabContentHeader';
import { Button } from '@/components/ui/Buttons';
import { FormGroup, Label } from '@/components/ui/Forms';
import PaymentStatus from '@/components/PaymentStatus';
import {
  cardNumberValidation,
  minLength,
  cardExpiryValidation,
  CARDSREGEX,
  EXPIRYDATE,
  CVC,
  AMERICANEXPRESS,
  PIN
} from '@/utils/card';
import useOnClickOutside from '@/hooks/useOnClickOutside';

const reducer = (state: PayWithCardProps, action: { data: any; type: any }) => {
  // console.log('action', action.data);
  switch (action.type) {
    case 'card':
      return { ...state, card: action.data };
    case 'expiry':
      return { ...state, expiry: action.data };
    case 'cvv':
      return { ...state, cvv: action.data };
    case 'pin':
      return { ...state, pin: action.data };
    case 'cleanState':
      return { ...action.data };
    default:
      return state;
  }
};

interface ICardDetailsFormProps {
  config: ITAPPaymentProps;
}

const CardDetailsForm = ({ config }: ICardDetailsFormProps) => {
  const { recipient, amount, currency } = config;
  const [disabled, setIsDisabled] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [error, setError] = useState({
    cardError: '',
    expiryError: '',
    cvvError: '',
    pinError: ''
  });
  const [status, setStatus] = useState<keyof typeof PaymentStatus | string>('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [state, dispatch] = useReducer(reducer, {
    card: '',
    expiry: '',
    cvv: '',
    pin: ''
  });
  const clickRef = useRef<any>();

  const handleCloseShowHelp = () => {
    setShowHelp(false);
  };

  useOnClickOutside(clickRef, handleCloseShowHelp);

  const handleValidations = (type: any, value: any) => {
    let errorText;
    switch (type) {
      case 'card':
        errorText = cardNumberValidation(value);
        setError({ ...error, cardError: errorText! });
        break;
      case 'expiry':
        errorText = value === '' ? 'Required' : cardExpiryValidation(value);
        setError({ ...error, expiryError: errorText! });
        break;
      case 'cvv':
        errorText = value === '' ? 'Required' : minLength(3)(value);
        setError({ ...error, cvvError: errorText! });
        break;
      case 'pin':
        errorText = value === '' ? 'Required' : minLength(4)(value);
        setError({ ...error, pinError: errorText! });
        break;
      default:
        break;
    }
  };

  const handleInputData = (e: any) => {
    dispatch({ type: e.target.name, data: e.target.value });
  };

  const handleBlur = (e: any) => {
    handleValidations(e.target.name, e.target.value);
  };

  const checkErrorBeforeSave = () => {
    let errorValue: any = {};
    let isError = false;
    Object.keys(state).forEach(async (val) => {
      if (state[val] === '') {
        errorValue = { ...errorValue, [`${val + 'Error'}`]: 'Required' };
        isError = true;
        setIsDisabled(isError);
      }
    });
    setError(errorValue);
    return isError;
  };

  const handleMakePayment = (e: any) => {
    const errorCheck = checkErrorBeforeSave();
    if (!errorCheck) {
      console.log(state);
      setIsSubmitting(true);
      setTimeout(() => {
        setIsSubmitting(false);
        setStatus('fail');
      }, 3000);
    }
  };

  useEffect(() => {
    Object.values(error).forEach(async (val) => {
      if (val === '' || val === undefined) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    });

    Object.keys(state).forEach(async (val) => {
      if (state[val] === '' || state[val] === undefined) {
        setIsDisabled(true);
      }
    });
  }, [error, state]);

  if (status?.length > 0) {
    return (
      <PaymentStatus
        config={config}
        status={status}
        onClick={() => {
          setStatus('');
        }}
      />
    );
  }

  return (
    <div className="account-details">
      <TabContentHeader config={config} />
      <div className="flex flex-col justify-center py-5">
        <form action="" className="w-full">
          <FormGroup>
            <Label htmlFor="card_number">Card Number</Label>
            <MaskedInput
              mask={
                ['37', '34'].includes(
                  state && state.card.split('').splice(0, 2).join('')
                )
                  ? AMERICANEXPRESS
                  : CARDSREGEX
              }
              guide={false}
              className="form-control"
              placeholderChar={'\u2000'}
              placeholder="0000 0000 0000 0000"
              name="card"
              id="card"
              required
              value={state.card}
              onChange={handleInputData}
              onBlur={handleBlur}
            />
            {error && error.cardError && error.cardError.length > 1 && (
              <span className="text-danger">{error.cardError}</span>
            )}
          </FormGroup>

          <div className="relative flex w-full items-center gap-4 overflow-visible">
            <FormGroup>
              <Label htmlFor="expires">Expiry</Label>
              <MaskedInput
                mask={EXPIRYDATE}
                guide={false}
                className="form-control"
                placeholderChar={'\u2000'}
                placeholder="MM/YY"
                id="expiry"
                name="expiry"
                required
                value={state.expiry}
                onChange={handleInputData}
                onBlur={handleBlur}
              />
              {error && error.expiryError && error.expiryError.length > 1 && (
                <span className="text-danger">{error.expiryError}</span>
              )}
            </FormGroup>
            <FormGroup>
              <div className="flex items-center justify-between">
                <Label htmlFor="cvv">CVV</Label>
                <div
                  className="mb-2 cursor-pointer"
                  onClick={() => setShowHelp((prev) => !prev)}
                >
                  <SVG.CVVHelp />
                </div>
              </div>
              <MaskedInput
                mask={CVC}
                guide={false}
                className="form-control"
                placeholderChar={'\u2000'}
                placeholder="000"
                id="cvv"
                name="cvv"
                required
                value={state.cvv}
                onChange={handleInputData}
                onBlur={handleBlur}
              />
              {error && error.cvvError && error.cvvError.length > 1 && (
                <span className="text-danger">{error.cvvError}</span>
              )}
            </FormGroup>
            {showHelp && (
              <div
                ref={clickRef}
                className="help-tooltip absolute -right-3 left-24 top-8 z-50 rounded-md bg-[#1B3358] p-4 text-sm text-white shadow-tab"
              >
                CVV is the 3-digits code located on the back of your
                credit/debit card on the right side of the white signature strip
              </div>
            )}
          </div>

          <FormGroup>
            <Label htmlFor="pin">Card Pin</Label>
            <MaskedInput
              mask={PIN}
              guide={false}
              className="form-control"
              placeholderChar={'\u2000'}
              placeholder="0000"
              id="pin"
              name="pin"
              required
              value={state.pin}
              onChange={handleInputData}
              onBlur={handleBlur}
            />
            {error && error.pinError && error.pinError.length > 1 && (
              <span className="text-danger">{error.pinError}</span>
            )}
          </FormGroup>
          <Button
            fullWidth
            onClick={handleMakePayment}
            isLoading={isSubmitting}
            disabled={disabled}
          >
            Make Payment
          </Button>
        </form>
      </div>
    </div>
  );
};

export default CardDetailsForm;
