import * as SVG from '@/assets/svgs';
import { Button } from '@/components/ui/Buttons';
import { Paragraph } from '@/components/ui/Typography';

const creditCards: ICreditCardProps[] = [
  {
    cardNumber: '5347  0849  ****  **44',
    cardType: <SVG.VISASVG />,
    lastUsed: '23. 10. 2023'
  },
  {
    cardNumber: '5347  5749  ****  **98',
    cardType: <SVG.MasterCardSVG />,
    lastUsed: '23. 10. 2023'
  },
  {
    cardNumber: '5347  4838  ****  **85',
    cardType: <SVG.VISASVG />,
    lastUsed: '23. 10. 2023'
  },
  {
    cardNumber: '5347  0238  ****  **34',
    cardType: <SVG.MasterCardSVG />,
    lastUsed: '23. 10. 2023'
  }
];

interface ICreditCardsProps {
  setSelected: (e: ICreditCardProps) => void;
  setTokenizedStep: (e: number) => void;
}

const CreditCards = ({ setSelected, setTokenizedStep }: ICreditCardsProps) => {
  return (
    <div className="credit-cards grid grid-cols-1 gap-4">
      {creditCards?.map((card) => (
        <CreditCardItem
          key={card.cardNumber}
          creditCard={card}
          setSelected={setSelected}
          setTokenizedStep={setTokenizedStep}
        />
      ))}
    </div>
  );
};

export default CreditCards;

interface ICreditCardItemProps {
  creditCard: ICreditCardProps;
  setSelected: (e: ICreditCardProps) => void;
  setTokenizedStep: (e: number) => void;
}
const CreditCardItem = ({
  creditCard,
  setSelected,
  setTokenizedStep
}: ICreditCardItemProps) => {
  const { cardNumber, cardType, lastUsed } = creditCard;

  const handleSelect = () => {
    setSelected(creditCard);
    setTokenizedStep(1);
  };
  return (
    <div className="credit-card-item rounded-lg p-4 shadow-tab">
      <div className="mb-4 flex items-center justify-between">
        <Paragraph className="text-base font-semibold text-black">
          {cardNumber}
        </Paragraph>
        <div>{cardType}</div>
      </div>

      <div className="flex items-center justify-between">
        <Paragraph className="text-xs font-semibold text-[#A0A0A0]">
          Last used {lastUsed}
        </Paragraph>
        <Button
          flat
          onClick={handleSelect}
          className="gap-x-2 p-0 font-semibold hover:bg-transparent hover:underline"
        >
          Continue <SVG.ChevronRight />
        </Button>
      </div>
    </div>
  );
};
