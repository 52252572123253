import { useState } from 'react';
import { H6 } from '@/components/ui/Typography';
import { FormGroup, Input, Label } from '@/components/ui/Forms';
import { Button } from '@/components/ui/Buttons';
import { minLength, phoneValidation } from '@/utils/card';

interface IPhoneNumberProps {
  phone: string;
  setPhone: (e: string) => void;
  setStep: (e: number) => void;
}

const PhoneNumber = ({ phone, setPhone, setStep }: IPhoneNumberProps) => {
  const [error, setError] = useState('');

  const handleValidations = () => {
    const errorText = phone === '' ? 'Required' : phoneValidation(phone);
    setError(errorText!);
  };

  const handleBlur = () => {
    handleValidations();
  };

  const handleProceed = () => {
    if (error?.length === 0) {
      setStep(1);
    }
  };

  return (
    <div className="phone-number">
      <header className="mb-5">
        <H6 className="max-w-[16rem] sm:max-w-none">
          Please input your phone number
        </H6>
      </header>
      <div>
        <form onSubmit={handleProceed}>
          <FormGroup className="mb-12">
            <Label htmlFor="phone">Phone number</Label>
            <Input
              name="phone"
              placeholder="09192393922"
              value={phone}
              onBlur={handleBlur}
              onChange={(e) => setPhone(e.target.value)}
            />
            {error && error.length > 1 && (
              <span className="text-danger">{error}</span>
            )}
          </FormGroup>
          <Button
            fullWidth
            type="submit"
            disabled={phone.length !== 11 || error?.length > 0}
          >
            Proceed
          </Button>
        </form>
      </div>
    </div>
  );
};

export default PhoneNumber;
