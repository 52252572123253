import { useState } from 'react';
import * as SVG from '@/assets/svgs';
import { H5, Paragraph } from '@/components/ui/Typography';
import { Button } from '@/components/ui/Buttons';
import PayWithWallet from './PayWithWallet';
import PayWithTokenizedCard from './PayWithTokenizedCard';

const payWithCowryOptions: IPayWithCowryOptions[] = [
  { icon: SVG.PayWithWallet, label: 'Wallet' },
  { icon: SVG.PayWithTokenizedCard, label: 'Tokenized card' }
];

interface IPayWithCowryOptionsProps {
  phone: string;
  setStep: (e: number) => void;
  config: ITAPPaymentProps;
}

const PayWithCowryOptions = ({
  phone,
  setStep,
  config
}: IPayWithCowryOptionsProps) => {
  const [selectedOption, setSelectedOption] =
    useState<IPayWithCowryOptions | null>(null);

  const renderOption = () => {
    switch (selectedOption!.label) {
      case 'Wallet':
        return (
          <PayWithWallet config={config} phone={phone} setStep={setStep} />
        );
      case 'Tokenized card':
        return (
          <PayWithTokenizedCard
            config={config}
            phone={phone}
            setStep={setStep}
          />
        );
    }
  };

  if (selectedOption!?.label?.length > 0) {
    return (
      <div className="w-full">
        <header className="mb-8 flex w-full items-center gap-3">
          <H5 className="w-full text-center text-base">
            {selectedOption!.label}
          </H5>
        </header>
        {renderOption()}
        <Button
          flat
          className="w-full text-sm hover:bg-transparent hover:underline"
          onClick={() => {
            setStep(2);
            setSelectedOption(null);
          }}
        >
          Go back
        </Button>
      </div>
    );
  }

  return (
    <div className="pay-with-transfer h-full w-full">
      <header className="mt-5 mb-10 sm:mt-0">
        <H5 className="max-w-xs text-cowry-title sm:text-lg">
          Please select a channel to pay with{' '}
        </H5>
      </header>
      <div className="overflow-hidden rounded-lg bg-white shadow-tab">
        {payWithCowryOptions.map((option) => (
          <Button
            key={option.label}
            onClick={() => setSelectedOption(option)}
            className="flex w-full cursor-pointer items-center justify-between rounded-none border-b bg-white p-5 last:border-b-0 hover:bg-primary-5"
          >
            <div className="flex items-center gap-4">
              <div>
                <option.icon />
              </div>
              <Paragraph className="text-sm font-bold text-cowry-title">
                {option.label}
              </Paragraph>
            </div>
            <div className="mobile-only inline-block sm:hidden">
              <SVG.ChevronRight />
            </div>
          </Button>
        ))}
      </div>
    </div>
  );
};

export default PayWithCowryOptions;
