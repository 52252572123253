import moment from 'moment';

export const EXPIRYDATE = [/[0-9]/, /\d/, '/', /\d/, /\d/];

export const CVC = [/[0-9]/, /\d/, /\d/];

export const PIN = [/[0-9]/, /\d/, /\d/, /\d/];

export const PHONE =
  /^(0)(701|702|703|704|705|706|707|708|709|802|803|804|805|806|807|808|809|810|811|812|813|814|815|816|817|818|819|909|908|901|902|903|904|905|906|907)([0-9]{7})$/;

export const CARDSREGEX = [
  /[1-9]/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

export const AMERICANEXPRESS = [
  /[1-9]/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

export const MASTERCARDREGEX = /^5[1-5][0-9]{1,}|^2[2-7][0-9]{1,}$/;

export const VISAREGEX = /^4[0-9]{2,}$/;

export const regexPattern: any = {
  MASTERCARD: MASTERCARDREGEX,
  VISA: VISAREGEX,
  AMERICAN_EXPRESS: /^3[47][0-9]{5,}$/,
  DISCOVER: /^6(?:011|5[0-9]{2})[0-9]{3,}$/,
  DINERS_CLUB: /^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/,
  JCB: /^(?:2131|1800|35[0-9]{3})[0-9]{3,}$/
};

// card validation
export function cardNumberValidation(cardNumber: string) {
  for (const card in regexPattern) {
    if (cardNumber.replace(/[^\d]/g, '').match(regexPattern[card])) {
      if (cardNumber) {
        return cardNumber &&
          /^[1-6]{1}[0-9]{14,15}$/i.test(
            cardNumber.replace(/[^\d]/g, '').trim()
          )
          ? ''
          : 'Enter a valid Card';
      }
    }
  }
  return 'Enter a valid Card';
}

// expiry validation
export const cardExpiryValidation = (value: any) => {
  if (value) {
    if (/^(0[1-9]|1[0-2])\/[0-9]{2}$/i.test(value.trim())) {
      const today = new Date();
      const CurrentDate = moment(
        new Date(
          today.getFullYear() +
            '-' +
            (today.getMonth() + 1) +
            '-' +
            new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate()
        )
      );
      const visaValue = value.split('/');
      const visaDate = new Date(Number(`20${visaValue[1]}`), visaValue[0], 0);
      return CurrentDate < moment(visaDate)
        ? undefined
        : 'Please enter valid date';
    } else {
      return 'Invalid date format';
    }
  }
};

export const phoneValidation = (phone: string) => {
  if (phone.match(PHONE)) {
    return '';
  }
  minLength(11)(phone);
  return 'Enter a valid phone number';
};

// length validation
export const minLength = (min: number) => (value: string | any[]) =>
  value && value.length < min ? `Must be ${min} characters` : undefined;
