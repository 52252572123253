import clsxm from '@/utils/clsxm';

const MasterCard = ({ className }: SVGJSX) => (
  <svg
    width="46"
    height="36"
    viewBox="0 0 46 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsxm('text-primary', className)}
  >
    <g clipPath="url(#clip0_4242_37898)">
      <path
        d="M16.4102 3.47266H28.5726V25.3258H16.4102V3.47266Z"
        fill="#FF5F00"
      />
      <path
        d="M17.1821 14.3996C17.1821 9.9595 19.267 6.02121 22.4716 3.47289C20.1165 1.61965 17.1435 0.5 13.9002 0.5C6.21631 0.5 0 6.71611 0 14.3996C0 22.0829 6.21631 28.2991 13.9 28.2991C17.1434 28.2991 20.1163 27.1795 22.4716 25.3261C19.267 22.8165 17.1821 18.8396 17.1821 14.3996Z"
        fill="#EB001B"
      />
      <path
        d="M44.9833 14.3996C44.9833 22.0829 38.767 28.2991 31.0833 28.2991C27.84 28.2991 24.867 27.1795 22.5117 25.3261C25.755 22.7779 27.8015 18.8396 27.8015 14.3996C27.8015 9.9595 25.7163 6.02121 22.5117 3.47289C24.8668 1.61965 27.84 0.5 31.0833 0.5C38.767 0.5 44.9833 6.7548 44.9833 14.3996Z"
        fill="#F79E1B"
      />
    </g>
    {/* <defs>
      <clipPath id="clip0_4242_37898">
        <rect
          width="45.0266"
          height="35"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs> */}
  </svg>
);

export default MasterCard;
