import { useState } from 'react';
import OtpInput from 'react18-input-otp';
import * as SVG from '@/assets/svgs';
import TabContentHeader from '@/components/TabContentHeader';
import { Button } from '@/components/ui/Buttons';
import Image from '@/components/ui/Image';
import { Paragraph } from '@/components/ui/Typography';

interface ICowryPinProps {
  config: ITAPPaymentProps;
  phone: string;
  setStep: (e: number) => void;
}

const CowryPin = ({ config, phone, setStep }: ICowryPinProps) => {
  const [cowryPin, setCowryPin] = useState('');

  const onProceed = () => {
    console.log({ phone, cowryPin });
    setStep(2);
  };

  return (
    <div className="">
      <TabContentHeader config={config} />
      <div className="m-auto flex max-h-full  min-h-full w-full flex-col justify-center py-10 text-center sm:py-0">
        <div className="mb-5 flex justify-center">
          <Image className="h-24 w-24" src={SVG.CardPin} />
        </div>
        <Paragraph className="mx-auto max-w-[16rem] text-center">
          Enter your cowry PIN
        </Paragraph>

        <form action="">
          <div className="pin my-10 flex w-full justify-center">
            <OtpInput
              value={cowryPin}
              onChange={(pin: any) => setCowryPin(pin)}
              numInputs={4}
              isInputNum
              shouldAutoFocus
              inputStyle="pin-input min-w-[3.5rem] w-12 max-[1030px]:w-[35px] max-[770px]:w-[42px] max-[541px]:w-[58.5px] max-[418px]:w-[46.5px] max-[380px]:w-[41px] max-[361px]:w-[39.5px] h-[3.5rem] bg-white border border-solid border-widget-border dark:bg-card-dark rounded-lg text-primary font-semibold focus:border-primary focus:outline-none bg-widget-background"
              containerStyle="gap-x-3"
            />
          </div>
        </form>

        <Button
          onClick={onProceed}
          className="mt-10 text-sm disabled:bg-btn-disabled hover:disabled:bg-btn-disabled"
          disabled={cowryPin.length !== 4}
        >
          Proceed
        </Button>
      </div>
    </div>
  );
};

export default CowryPin;
