import { Paragraph } from './ui/Typography';

interface ITabContentHeaderProps {
  config: ITAPPaymentProps;
}

const TabContentHeader = ({ config }: ITabContentHeaderProps) => {
  const { email, amount, currency } = config;

  return (
    <header className="pt-3 pb-5">
      <Paragraph className="text-xs font-bold text-primary">
        {currency} {amount.toFixed(2)}
      </Paragraph>
      <Paragraph className="text-[11px]">{email}</Paragraph>
    </header>
  );
};

export default TabContentHeader;
