import clsxm from '@/utils/clsxm';

const SuccessCheck = ({ className, stroke = '#1FAD64' }: SVGJSX) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsxm('text-primary', className)}
  >
    <circle cx="12" cy="12" r="12" fill="#DEF7EA" />
    <path
      d="M16.7273 9L10.7273 15L8 12.2727"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SuccessCheck;
