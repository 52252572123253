import { useState } from 'react';
import AccountDetails from './components/AccountDetails';
import Waiting from './components/Waiting';

interface IPayWithTransferProps {
  step?: number;
  setStep?: (e: number) => void;
  config: ITAPPaymentProps;
}

const PayWithTransfer = ({ step, setStep, config }: IPayWithTransferProps) => {
  const { email, amount, currency } = config;
  const [status, setStatus] = useState<keyof typeof PaymentStatus | string>('');

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <AccountDetails
            config={config}
            setStep={setStep!}
            setStatus={setStatus}
          />
        );
      case 1:
        return (
          <Waiting
            config={config}
            setStep={setStep!}
            status={status}
            setStatus={setStatus}
          />
        );
    }
  };

  return <div className="pay-with-transfer h-full w-full">{renderStep()}</div>;
};

export default PayWithTransfer;
