import clsxm from '@/utils/clsxm';

const Error = ({ className }: SVGJSX) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="21px"
    height="17px"
    viewBox="0 0 21 17"
    version="1.1"
    className={clsxm('text-primary', className)}
  >
    <title>error</title>
    <desc>Created with Sketch.</desc>
    <defs />
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="error" fillRule="nonzero">
        <path
          d="M9.14672,0.47855 L0.14829,15.47855 C-0.0403320234,15.7872042 -0.0475647902,16.1736607 0.129375884,16.4891566 C0.306316558,16.8046526 0.639843999,16.9999993 1.00157,17 L19.43546,17 C19.797186,16.9999993 20.1307134,16.8046526 20.3076541,16.4891566 C20.4845948,16.1736607 20.477362,15.7872042 20.28874,15.47855 L10.85328,0.47855 C10.671624,0.181297031 10.3483651,3.00996351e-06 10,3.00996351e-06 C9.6516349,3.00996351e-06 9.32837603,0.181297031 9.14672,0.47855 Z"
          id="Shape"
          fill="#FFAA22"
        />
        <rect
          id="Rectangle-path"
          fill="#FFFFFF"
          x="9"
          y="6"
          width="2"
          height="5"
        />
        <rect
          id="Rectangle-path"
          fill="#FFFFFF"
          x="9"
          y="12"
          width="2"
          height="2"
        />
      </g>
    </g>
  </svg>
);

export default Error;
