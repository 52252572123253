import CardDetailsForm from './components/CardDetailsForm';

interface IPayWithCardProps {
  config: ITAPPaymentProps;
}

const PayWithCard = ({ config }: IPayWithCardProps) => {
  return (
    <div className="pay-with-card h-full w-full">
      <CardDetailsForm config={config} />
    </div>
  );
};

export default PayWithCard;
