import clsxm from '@/utils/clsxm';

const CircularProgress = ({
  className,
  stroke = '#0E60E2',
  fill = '#ffffff'
}: SVGJSX) => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsxm('text-primary', className)}
  >
    <circle cx="25" cy="25" r="23" stroke="#F3F5F7" strokeWidth="4" />
    <mask id="path-2-inside-1_4271_37562" fill={fill}>
      <path d="M48.0506 25C49.1272 25 50.0079 24.1259 49.924 23.0526C49.7196 20.4365 49.1043 17.8647 48.097 15.4329C46.8406 12.3998 44.9991 9.6438 42.6777 7.32233C40.3562 5.00087 37.6002 3.15938 34.5671 1.90301C32.1353 0.895729 29.5635 0.280371 26.9474 0.075964C25.8741 -0.0079016 25 0.872771 25 1.94939C25 3.02601 25.8746 3.88944 26.9466 3.98876C29.0503 4.18365 31.1165 4.69372 33.0751 5.50501C35.6352 6.56545 37.9614 8.11975 39.9208 10.0792C41.8802 12.0386 43.4346 14.3648 44.495 16.9249C45.3063 18.8835 45.8163 20.9497 46.0112 23.0534C46.1106 24.1254 46.974 25 48.0506 25Z" />
    </mask>
    <path
      d="M48.0506 25C49.1272 25 50.0079 24.1259 49.924 23.0526C49.7196 20.4365 49.1043 17.8647 48.097 15.4329C46.8406 12.3998 44.9991 9.6438 42.6777 7.32233C40.3562 5.00087 37.6002 3.15938 34.5671 1.90301C32.1353 0.895729 29.5635 0.280371 26.9474 0.075964C25.8741 -0.0079016 25 0.872771 25 1.94939C25 3.02601 25.8746 3.88944 26.9466 3.98876C29.0503 4.18365 31.1165 4.69372 33.0751 5.50501C35.6352 6.56545 37.9614 8.11975 39.9208 10.0792C41.8802 12.0386 43.4346 14.3648 44.495 16.9249C45.3063 18.8835 45.8163 20.9497 46.0112 23.0534C46.1106 24.1254 46.974 25 48.0506 25Z"
      stroke={stroke}
      strokeWidth="8"
      mask="url(#path-2-inside-1_4271_37562)"
    />
  </svg>
);

export default CircularProgress;
