import clsxm from '@/utils/clsxm';

const ChevronRight = ({ className, fill = '#0E60E2' }: SVGJSX) => (
  <svg
    width="6"
    height="11"
    viewBox="0 0 6 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsxm('text-primary', className)}
  >
    <path
      d="M1.30188 10.3083C1.17583 10.4324 1.01541 10.5 0.826337 10.5C0.448195 10.5 0.144536 10.2069 0.144536 9.83484C0.144536 9.64882 0.224748 9.47971 0.356525 9.35006L4.36712 5.49436L0.356525 1.64994C0.224748 1.52029 0.144536 1.34555 0.144536 1.16516C0.144536 0.793123 0.448195 0.5 0.826337 0.5C1.01541 0.5 1.17583 0.567644 1.30188 0.691657L5.75936 4.97576C5.91979 5.12232 5.99427 5.30271 6 5.5C6 5.69729 5.91979 5.8664 5.75936 6.0186L1.30188 10.3083Z"
      fill={fill}
    />
  </svg>
);

export default ChevronRight;
